<template>
  <div>
    <v-btn color="primary" class="text-none" @click="submit" :loading="loading">
      {{ buttonText }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    submit: {
      type: Function,
    },
    buttonText: {
      type: String,
      default: "Submit",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
