<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <div class="pt-12">
          <div class="text-center">
            <v-icon size="96" color="primary">
              mdi-close-circle-outline
            </v-icon>
          </div>

          <div class="pt-6">
            <h2 class="text-center">
              Gagal
            </h2>

            <p class="text-center px-4">
              {{ errorMessage }}
            </p>
          </div>

          <v-card-actions class="py-8">
            <v-spacer></v-spacer>
            <v-btn color="primary" class="text-none px-12" @click="closeDialog">
              Tutup
            </v-btn>

            <v-spacer></v-spacer>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    errorMessage: {
      type: String,
      default: "Terjadi kesalahan",
    },
    closeDialog: Function,
  },
};
</script>
